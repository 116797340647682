export const languages = Object.freeze([
  {
    id: 6,
    name: 'Arabic - Saudi Arabia',
    code: 'ar-sa',
    parent: 'ar',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 32,
    name: 'German - Germany',
    code: 'de-de',
    parent: 'de',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 67,
    name: 'Spanish - Spain (Traditional)',
    code: 'es-es',
    parent: 'es',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 76,
    name: 'French - France',
    code: 'fr-fr',
    parent: 'fr',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 90,
    name: 'Italian - Italy',
    code: 'it-it',
    parent: 'it',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 91,
    name: 'Japanese',
    code: 'ja',
    parent: 'ja',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 2,
    name: 'Amharic',
    code: 'am',
    parent: 'am',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 3,
    name: 'Arabic - Morocco',
    code: 'ar-ma',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 4,
    name: 'Arabic - Oman',
    code: 'ar-om',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 5,
    name: 'Arabic - Qatar',
    code: 'ar-qa',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 7,
    name: 'Arabic - Syria',
    code: 'ar-sy',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 8,
    name: 'Arabic - Tunisia',
    code: 'ar-tn',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 9,
    name: 'Arabic - Yemen',
    code: 'ar-ye',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 10,
    name: 'Arabic - Libya',
    code: 'ar-ly',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 11,
    name: 'Arabic - Lebanon',
    code: 'ar-lb',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 13,
    name: 'Arabic - Bahrain',
    code: 'ar-bh',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 14,
    name: 'Arabic - Algeria',
    code: 'ar-dz',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 15,
    name: 'Arabic - Egypt',
    code: 'ar-eg',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 16,
    name: 'Arabic - Iraq',
    code: 'ar-iq',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 17,
    name: 'Arabic - Jordan',
    code: 'ar-jo',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 18,
    name: 'Arabic - Kuwait',
    code: 'ar-kw',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 19,
    name: 'Assamese',
    code: 'as',
    parent: 'as',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 20,
    name: 'Azeri - Cyrillic',
    code: 'az-az',
    parent: 'az',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 21,
    name: 'Belarusian',
    code: 'be',
    parent: 'be',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 22,
    name: 'Bulgarian',
    code: 'bg',
    parent: 'bg',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 23,
    name: 'Bengali - Bangladesh',
    code: 'bn',
    parent: 'bn',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 24,
    name: 'Tibetan',
    code: 'bo',
    parent: 'bo',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 25,
    name: 'Bosnian',
    code: 'bs',
    parent: 'bs',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 26,
    name: 'Catalan',
    code: 'ca',
    parent: 'ca',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 27,
    name: 'Czech',
    code: 'cs',
    parent: 'cs',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 28,
    name: 'Welsh',
    code: 'cy',
    parent: 'cy',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 29,
    name: 'Danish',
    code: 'da',
    parent: 'da',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 30,
    name: 'German - Luxembourg',
    code: 'de-lu',
    parent: 'de',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 31,
    name: 'German - Liechtenstein',
    code: 'de-li',
    parent: 'de',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 33,
    name: 'German - Switzerland',
    code: 'de-ch',
    parent: 'de',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 34,
    name: 'German - Austria',
    code: 'de-at',
    parent: 'de',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 35,
    name: 'Greek',
    code: 'el',
    parent: 'el',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 36,
    name: 'English - Southern Africa',
    code: 'en-za',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 38,
    name: 'English - Trinidad',
    code: 'en-tt',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 39,
    name: 'English - Phillippines',
    code: 'en-ph',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 41,
    name: 'English - Jamaica',
    code: 'en-jm',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 42,
    name: 'English - India',
    code: 'en-in',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 43,
    name: 'English - Ireland',
    code: 'en-ie',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 44,
    name: 'English - Australia',
    code: 'en-au',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 45,
    name: 'English - Belize',
    code: 'en-bz',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 46,
    name: 'English - Canada',
    code: 'en-ca',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 47,
    name: 'English - Caribbean',
    code: 'en-cb',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 49,
    name: 'Spanish - Nicaragua',
    code: 'es-ni',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 50,
    name: 'Spanish - Panama',
    code: 'es-pa',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 51,
    name: 'Spanish - Peru',
    code: 'es-pe',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 52,
    name: 'Spanish - Puerto Rico',
    code: 'es-pr',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 53,
    name: 'Spanish - Paraguay',
    code: 'es-py',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 54,
    name: 'Spanish - El Salvador',
    code: 'es-sv',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 55,
    name: 'Spanish - Uruguay',
    code: 'es-uy',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 56,
    name: 'Spanish - Venezuela',
    code: 'es-ve',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 57,
    name: 'Spanish - Mexico',
    code: 'es-mx',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 58,
    name: 'Spanish - Honduras',
    code: 'es-hn',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 59,
    name: 'Spanish - Guatemala',
    code: 'es-gt',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 60,
    name: 'Spanish - Argentina',
    code: 'es-ar',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 61,
    name: 'Spanish - Bolivia',
    code: 'es-bo',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 62,
    name: 'Spanish - Chile',
    code: 'es-cl',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 63,
    name: 'Spanish - Colombia',
    code: 'es-co',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 65,
    name: 'Spanish - Dominican Republic',
    code: 'es-do',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 66,
    name: 'Spanish - Ecuador',
    code: 'es-ec',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 68,
    name: 'Estonian',
    code: 'et',
    parent: 'et',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 69,
    name: 'Basque',
    code: 'eu',
    parent: 'eu',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 70,
    name: 'Farsi - Persian',
    code: 'fa',
    parent: 'fa',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 71,
    name: 'Finnish',
    code: 'fi',
    parent: 'fi',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 72,
    name: 'Faroese',
    code: 'fo',
    parent: 'fo',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 73,
    name: 'French - Belgium',
    code: 'fr-be',
    parent: 'fr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 74,
    name: 'French - Canada',
    code: 'fr-ca',
    parent: 'fr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 75,
    name: 'French - Switzerland',
    code: 'fr-ch',
    parent: 'fr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 77,
    name: 'French - Luxembourg',
    code: 'fr-lu',
    parent: 'fr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 78,
    name: 'Gaelic - Ireland',
    code: 'gd-ie',
    parent: 'gd',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 79,
    name: 'Gaelic - Scotland',
    code: 'gd',
    parent: 'gd',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 80,
    name: 'Guarani - Paraguay',
    code: 'gn',
    parent: 'gn',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 81,
    name: 'Gujarati',
    code: 'gu',
    parent: 'gu',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 82,
    name: 'Hebrew',
    code: 'he',
    parent: 'he',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 83,
    name: 'Hindi',
    code: 'hi',
    parent: 'hi',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 84,
    name: 'Croatian',
    code: 'hr',
    parent: 'hr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 85,
    name: 'Hungarian',
    code: 'hu',
    parent: 'hu',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 86,
    name: 'Armenian',
    code: 'hy',
    parent: 'hy',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 87,
    name: 'Indonesian',
    code: 'id',
    parent: 'id',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 88,
    name: 'Icelandic',
    code: 'is',
    parent: 'is',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 92,
    name: 'Kazakh',
    code: 'kk',
    parent: 'kk',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 93,
    name: 'Khmer',
    code: 'km',
    parent: 'km',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 95,
    name: 'Korean',
    code: 'ko',
    parent: 'ko',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 113,
    name: 'Dutch - Netherlands',
    code: 'nl-nl',
    parent: 'nl',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 116,
    name: 'Polish',
    code: 'pl',
    parent: 'pl',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 122,
    name: 'Russian',
    code: 'ru',
    parent: 'ru',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 139,
    name: 'Thai',
    code: 'th',
    parent: 'th',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 1,
    name: 'Afrikaans',
    code: 'af',
    parent: 'af',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 48,
    name: 'English - Great Britain',
    code: 'en-gb',
    parent: 'en',
    status: '1',
    clock12h: true,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 12,
    name: 'Arabic - United Arab Emirates',
    code: 'ar-ae',
    parent: 'ar',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 40,
    name: 'English - New Zealand',
    code: 'en-nz',
    parent: 'en',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 64,
    name: 'Spanish - Costa Rica',
    code: 'es-cr',
    parent: 'es',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 89,
    name: 'Italian - Switzerland',
    code: 'it-ch',
    parent: 'it',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 94,
    name: 'Kannada',
    code: 'kn',
    parent: 'kn',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 96,
    name: 'Kashmiri',
    code: 'ks',
    parent: 'ks',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 97,
    name: 'Latin',
    code: 'la',
    parent: 'la',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 98,
    name: 'Lao',
    code: 'lo',
    parent: 'lo',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 99,
    name: 'Lithuanian',
    code: 'lt',
    parent: 'lt',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 100,
    name: 'Latvian',
    code: 'lv',
    parent: 'lv',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 101,
    name: 'Maori',
    code: 'mi',
    parent: 'mi',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 102,
    name: 'FYRO Macedonia',
    code: 'mk',
    parent: 'mk',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 103,
    name: 'Malayalam',
    code: 'ml',
    parent: 'ml',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 104,
    name: 'Mongolian',
    code: 'mn',
    parent: 'mn',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 105,
    name: 'Marathi',
    code: 'mr',
    parent: 'mr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 106,
    name: 'Malay - Brunei',
    code: 'ms-bn',
    parent: 'ms',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 107,
    name: 'Malay - Malaysia',
    code: 'ms-my',
    parent: 'ms',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 108,
    name: 'Maltese',
    code: 'mt',
    parent: 'mt',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 109,
    name: 'Burmese',
    code: 'my',
    parent: 'my',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 110,
    name: 'Norwegian - Bokml',
    code: 'no-no',
    parent: 'nb',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 111,
    name: 'Nepali',
    code: 'ne',
    parent: 'ne',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 112,
    name: 'Dutch - Belgium',
    code: 'nl-be',
    parent: 'nl',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 114,
    name: 'Oriya',
    code: 'or',
    parent: 'or',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 115,
    name: 'Punjabi',
    code: 'pa',
    parent: 'pa',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 117,
    name: 'Portuguese - Portugal',
    code: 'pt-pt',
    parent: 'pt',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 118,
    name: 'Portuguese - Brazil',
    code: 'pt-br',
    parent: 'pt',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 119,
    name: 'Raeto-Romance',
    code: 'rm',
    parent: 'rm',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 120,
    name: 'Romanian - Romania',
    code: 'ro',
    parent: 'ro',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 121,
    name: 'Romanian - Moldova',
    code: 'ro-mo',
    parent: 'ro',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 123,
    name: 'Russian - Moldova',
    code: 'ru-mo',
    parent: 'ru',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 124,
    name: 'Sanskrit',
    code: 'sa',
    parent: 'sa',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 125,
    name: 'Sorbian',
    code: 'sb',
    parent: 'sb',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 126,
    name: 'Sindhi',
    code: 'sd',
    parent: 'sd',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 127,
    name: 'Sinhala',
    code: 'si',
    parent: 'Si',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 128,
    name: 'Slovak',
    code: 'sk',
    parent: 'sk',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 129,
    name: 'Slovenian',
    code: 'sl',
    parent: 'sl',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 130,
    name: 'Somali',
    code: 'so',
    parent: 'so',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 131,
    name: 'Albanian',
    code: 'sq',
    parent: 'sq',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 132,
    name: 'Serbian - Cyrillic',
    code: 'sr-sp',
    parent: 'sr',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 133,
    name: 'Swedish - Finland',
    code: 'sv-fi',
    parent: 'sv',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 134,
    name: 'Swedish - Sweden',
    code: 'sv-se',
    parent: 'sv',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 135,
    name: 'Swahili',
    code: 'sw',
    parent: 'sw',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 136,
    name: 'Tamil',
    code: 'ta',
    parent: 'ta',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 137,
    name: 'Telugu',
    code: 'te',
    parent: 'te',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 138,
    name: 'Tajik',
    code: 'tg',
    parent: 'tg',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 140,
    name: 'Turkmen',
    code: 'tk',
    parent: 'tk',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 141,
    name: 'Setsuana',
    code: 'tn',
    parent: 'tn',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 143,
    name: 'Tsonga',
    code: 'ts',
    parent: 'ts',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 144,
    name: 'Tatar',
    code: 'tt',
    parent: 'tt',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 146,
    name: 'Urdu',
    code: 'ur',
    parent: 'ur',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 147,
    name: 'Uzbek - Cyrillic',
    code: 'uz-uz',
    parent: 'uz',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 148,
    name: 'Vietnamese',
    code: 'vi',
    parent: 'vi',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 149,
    name: 'Xhosa',
    code: 'xh',
    parent: 'xh',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 150,
    name: 'Yiddish',
    code: 'yi',
    parent: 'yi',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 152,
    name: 'Chinese - Hong Kong SAR',
    code: 'zh-hk',
    parent: 'zh',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 153,
    name: 'Chinese - Macau SAR',
    code: 'zh-mo',
    parent: 'zh',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 154,
    name: 'Chinese - Singapore',
    code: 'zh-sg',
    parent: 'zh',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 156,
    name: 'Zulu',
    code: 'zu',
    parent: 'zu',
    status: '0',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 151,
    name: 'Chinese - China',
    code: 'zh-cn',
    parent: 'zh',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 142,
    name: 'Turkish',
    code: 'tr',
    parent: 'tr',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 155,
    name: 'Chinese - Taiwan',
    code: 'zh-tw',
    parent: 'zh-tw',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 145,
    name: 'Ukrainian',
    code: 'uk',
    parent: 'uk',
    status: '1',
    clock12h: false,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
  {
    id: 37,
    name: 'English - United States',
    code: 'en-us',
    parent: 'en',
    status: '0',
    clock12h: true,
    createdAt: '2019-12-20T14:55:27.21757',
    updatedAt: '2019-12-20T14:55:27.21757',
  },
])
