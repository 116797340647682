<template>
  <div class="user-list">
    <UiBanner fluid :title="$t('user.list.name')">
      <template v-slot:actions>
        <div class="tw-flex tw-items-center">
          <v-text-field
            v-model="search"
            :value="search"
            :prepend-inner-icon="`${icons.mdiMagnify}`"
            :label="$t('user.list.search.label')"
            hide-details
            outlined
            solo
            flat
            dense
            clearable
            @input="searchInput"
          />

          <v-tooltip max-width="300px" color="error" bottom :disabled="!disabledCreateUser">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="disabledCreateUser"
                  class="tw-ml-4 tw-rounded-md"
                  color="primary"
                  :to="{ name: 'UserManage' }"
                >
                  {{ $t('user.list.search.button') }}
                </v-btn>
              </div>
            </template>
            <span>
              {{ $t('notification.error.client.identifier') }}
            </span>
          </v-tooltip>
        </div>
      </template>
    </UiBanner>

    <UiContainer no-padding>
      <div class="user-list__table-container">
        <v-data-table
          style="cursor: pointer"
          class="user-list__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :loading="refreshing"
          :headers="headers"
          :items="users"
          :options.sync="options"
          :search="search"
          :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          @click:row="goToUserEdit"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.lastName }} {{ item.firstName }}</span>
          </template>
          <template v-slot:[`item.defaultLocaleIsoCode`]="{ item }">
            <div v-if="item.defaultLocaleIsoCode" class="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div v-if="getSvgBy('lang', item.defaultLocaleIsoCode)">
                <v-img width="25" :src="getSvgBy('lang', item.defaultLocaleIsoCode)" />
              </div>
              <span>{{ getLangByCode(item.defaultLocaleIsoCode) }}</span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status === true" color="success">{{ icons.mdiCheckCircle }}</v-icon>
            <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'

import { debounce } from '@/utils/utilities.util'
import { getSvgBy } from '@/utils/flag.util'
import { getLangByCode } from '@/utils/languages.util'

export default {
  name: 'UserList',
  components: {
    UiBanner,
    UiContainer,
  },
  data: () => ({
    getSvgBy,
    getLangByCode,
    icons: {
      mdiMagnify,
      mdiCheckCircle,
      mdiCloseCircle,
    },
    refreshing: false,
    search: '',
    options: {
      itemsPerPage: 25,
    },
  }),
  async created() {
    this.refreshing = true
    await this.getUsers({ query: [{ key: 'clients.uuid', value: this.client.uuid }], options: { size: 100 } })
    this.refreshing = false
  },
  computed: {
    ...mapState({
      users: state => state.users.users,
      client: state => state.backoffice.currentClient,
    }),
    disabledCreateUser() {
      return !this.client.uuid
    },
    headers() {
      return [
        {
          text: this.$t('user.list.headers.email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('user.list.headers.name'),
          value: 'name',
          sortable: true,
        },
        {
          text: this.$t('user.list.headers.role'),
          value: 'role',
          sortable: true,
        },
        {
          text: this.$t('user.list.headers.client'),
          value: 'client_name',
          sortable: true,
        },
        {
          text: this.$t('user.list.headers.defaultLocaleIsoCode'),
          value: 'defaultLocaleIsoCode',
          sortable: true,
        },
        {
          text: this.$t('user.list.headers.status'),
          value: 'status',
          sortable: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
    }),
    goToUserEdit(user) {
      this.$router.push({ name: 'UserEdit', params: { id: user.uuid } })
    },
    searchInput: debounce(function () {
      if (this.options.page !== 1) {
        this.options.page = 1
      }
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
.user-list {
  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
