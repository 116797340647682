export const flagsSvg = Object.freeze([
  {
    lang: 'ar-sa',
    country: 'SA',
    path: 'ar.svg',
  },
  {
    lang: 'de-de',
    country: 'DE',
    path: 'de.svg',
  },
  {
    lang: 'en-gb',
    country: 'GB',
    path: 'en.svg',
  },
  {
    lang: 'fr-fr',
    country: 'FR',
    path: 'fr.svg',
  },
  {
    lang: 'it-it',
    country: 'IT',
    path: 'it.svg',
  },
  {
    lang: 'ja-jp',
    country: 'JP',
    path: 'ja.svg',
  },
  {
    lang: 'ko',
    country: 'KR',
    path: 'ko.svg',
  },
  {
    lang: 'nl-nl',
    country: 'NL',
    path: 'nl.svg',
  },
  {
    lang: 'pl-pl',
    country: 'PL',
    path: 'pl.svg',
  },
  {
    lang: 'ru-ru',
    country: 'RU',
    path: 'ru.svg',
  },
  {
    lang: 'th-th',
    country: 'TH',
    path: 'th.svg',
  },
  {
    lang: 'tr',
    country: 'TR',
    path: 'tr.svg',
  },
  {
    lang: 'uk',
    country: 'UA',
    path: 'uk-ua.svg',
  },
  {
    lang: 'zh-cn',
    country: 'CN',
    path: 'zh-cn.svg',
  },
  {
    lang: 'zh-tw',
    country: 'TW',
    path: 'zh-tw.svg',
  },
])
