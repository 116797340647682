import { flagsSvg } from '@/config/flagsSvg.config'

export const getSvgBy = (property, countryCode) => {
  const flag = flagsSvg.find(flagSvg => flagSvg[property] === countryCode)
  try {
    const path = require(`@/assets/images/flags/${flag.path}`)
    return path
  } catch (error) {
    return ''
  }
}
